@include desktop{
  .main-image{
    width: 100%;
    object-fit: cover;
  }
}

@include mobile{
  .main-image{
    width: 100%;
    object-fit: cover;
  }
}
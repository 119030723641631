@include desktop{
  .management-wrapper{
    display : flex;
      flex-direction: column;
      align-items: center;
      margin : 70px 0;
  
      .management-title{
        margin-bottom : 100px;
        font-size: 45px;
        font-weight: bold;
      }
  
      .management-details{
        width: 75%;
        .management-details-row{
          
          height: 200px;
          display: flex;
          .management-details-title{
            flex:2.5;
            font-size:40px;
            font-weight: bold;
          }
    
          .management-details-content{
            flex:6.5;
            display:flex;
            width: 300px;
            height: 200px;
            
            justify-content:space-between;
            .management-details-writes{
              font-size:40px;
              font-weight: 300;
              line-height: 150%;
              width: 70%;
            }
  
            .management-details-image{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 180px;
              height: 180px;
              object-fit: cover;
            }
  
            .management-details-value{
              width: 30%;
              height: 200px;
              
              border-radius: 20px;
              border: 1px solid black;
              // box-shadow: 0 1px 7px #555;
              
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              
  
              .management-icon{
                width: 75px;
                height: 75px;
                margin-bottom: 15px;
              }
              .management-value-writes{
                font-size:25px;
              }
            }
          }
        }
      }
      
  }
}


@include mobile{
  .management-wrapper{
    display : flex;
      flex-direction: column;
      align-items: center;
      margin : 50px 0;
  
      .management-title{
        margin-bottom : 30px;
        font-size: 25px;
        font-weight: bold;
      }
  
      .management-details{
        width: 90%;
        .management-details-row{
          margin-bottom: 30px;
          .management-details-title{
            font-size:20px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .management-details-content{
            display: flex;
            .management-details-writes{
              font-size:15px;
              font-weight: 300;
              line-height: 150%;
            }

  
            .management-details-value{
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: center;
              text-align:center;
              
              .management-icon{
                width: 30px;
                height: 30px;
                margin-bottom: 15px;
                align-items: center;
              }
              .management-value-writes{
                font-size:12px;
                display: inline-block;
              }
            }
          }
          
        }
      }
      
  }
}

// mixin 정의
@mixin mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}


// 데스크탑 : 최소 '데스크탑'사이즈
@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}
// Desktop
@include desktop{
  .footer-wrap{
    height: #{$footer-size};
    width: 100%;
    background-color: $footer-color;
    box-sizing: border-box;
    padding: 0 30px;
  
    display: flex;
    align-items: center; /* Center the content vertically */
  
  
    .footer-inner{
      .Logo{
        height:50px;
        margin-bottom:10px;
      }
      .footer-info {
        display : flex;
        margin-bottom: 10px;
      }
      
      .footer-Logo{
        font-size : 30px;
      }
      
      .footer-copyright{
          li {
              color:#999;
          }
      }
    }
  }
}

// Mobile
@include mobile{
  .footer-wrap{
    height: #{$mobile-footer-size};
    width: 100%;
    background-color: $footer-color;
    box-sizing: border-box;
    padding: 0 30px;
  
    display: flex;
    align-items: center; /* Center the content vertically */
  
  
    .footer-inner{
      .Logo{
        height:30px;
        margin-bottom:10px;
      }
      .footer-info {
        display : flex;
        font-size:12px;
        margin-bottom: 10px;
      }
      
      .footer-Logo{
        font-size : 30px;
      }
      
      .footer-copyright{
          li {
            font-size:12px;
              color:#999;
          }
      }
    }
  }
}
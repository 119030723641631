// Desktop
@include desktop{
  .header-wrap {
    position: fixed;
    width: 100%;
    background-color: $header-color;
    z-index: 100000;
    box-shadow: 0 1px 7px #555;
  
    .header-top{
      display : flex;
      width : 100%;
      height : $header-size;
      justify-content : space-between;
      align-items : center;
  
      box-sizing : border-box;
      padding : 0 60px;
      border-bottom : 0.75px solid #999;

  
      .Logo{
        height: 50px;
        cursor: pointer;
      }
  
      ul {
        display : flex;
        li button {
          cursor: pointer;
          height : $header-size;
          width : 150px;
          text-align: center;
          font-weight: bold;
          font-size:18px;
        }
      }
    }
  
    .header-bottom{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height:0;
      box-sizing: border-box;
      padding: 0 60px;
      overflow: hidden;


      transition-duration: 0.75s;
  
      .menu-details {
        display: flex;
        ul{
          width: 150px;
          text-align:center;
          li {
            margin : 15px 0 ;
          }
          li:hover{
              color : blue;
          }
        }
      }
    }
  
    .header-bottom.hovered{
      height: 150px;
    }
  }
}

// Mobile
@include mobile {
  .header-wrap {
    position: fixed;
    width: 100%;
    background-color: $header-color;
    z-index: 100000;
    box-shadow: 0 1px 7px #555;
  
    .header-top{
      display : flex;
      width : 100%;
      height : $mobile-header-size;
      justify-content : space-between;
      align-items : center;
      border-bottom : 0.75px solid #999;
      padding: 0 20px;
      box-sizing : border-box;

  
      .Logo{
        height: 30px;
        cursor: pointer;
      }

      .Menu{height: 20px;}
    }
    .header-bottom{
      height: 0;
      overflow: hidden;
      transition-duration: 0.75s;

      .menu{
        font-size:15px;
        .menu-sub{
          height: 35px;
          border-top: 0.5px solid black; 
   
          display:flex;
          align-items: center;
          justify-content: space-between;
          padding : 0 20px;
        }
        
        .menu-sub-details{
          padding: 0 40px;
          display:flex;
          flex-direction:column;
          justify-content: center;
          border-top: 0.5px solid black; 
          li {
            margin : 1.5px 0;
          }
          &.one{
            // height:30.5px;
            height: 0;
            overflow: hidden;
            transition-duration: 0.75s;
            &.hover{
              height: 30.5px;
            }
          }
          &.three{
            // height:70.5px;
            height: 0;
            overflow: hidden;
            transition-duration: 0.75s;
            &.hover{
              height: 70.5px;
            }
          }
          &.five{
            // height:92px;
    
            height: 0;
            overflow: hidden;
            transition-duration: 0.75s;
            &.hover{
              height:92px;
            }
          }
        }
      }
    }

    // .header-bottom.hovered{
    //   height: 168px;
    // }
  }
}

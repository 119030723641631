.contentImageSlider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contentImageSlider-wrapper2 {
  width: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.contentImageSlider-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* 추가 */
}

.contentImageSlider-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
  flex-shrink: 0;
}

.contentImageSlider-forward,
.contentImageSlider-back {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  opacity: 0.3;
  z-index: 3;
  cursor: pointer;
  transform: translateY(-50%); /* 추가 */
}

.contentImageSlider-forward {
  right: 15px;
}

.contentImageSlider-back {
  left: 15px;
}

.contentImageSlider-forward:hover,
.contentImageSlider-back:hover {
  opacity: 1;
}

@include mobile {
  .contentImageSlider-wrapper2 {
    width: 100%;
  }
  .contentImageSlider-image {
    height: 300px;
  }
  .contentImageSlider-forward,
  .contentImageSlider-back {
    width: 24px;
    height: 24px;
  }
}

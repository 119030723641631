/* Example CSS for the Content component */
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-wrapper-title {
    margin: 70px 0;
    font-size: 45px;
    font-weight: bold;
  }

  .content-wrapper-information {
    width: 40%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-wrapper-information-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .content-wrapper-information-details {
    display: flex;
    flex-wrap: wrap;
    border-top : 1px solid black;
    border-bottom : 1px solid black;
  }

  .content-wrapper-information-content {
    flex: 1 1 45%; /* Each li takes up 45% of the width, adjust as needed */
    margin: 10px; /* Add some margin for spacing */
    font-size:18px;
  }

  @include mobile{
    .content-wrapper-title {
      margin: 30px 0;
      font-size: 25px;
      font-weight: bold;
    }
    .content-wrapper-information-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .content-wrapper-information {
      width: 60%;
      height: 300px;
    }

    .content-wrapper-information-content {
      font-size:13px;
    }
  }
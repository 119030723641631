@include desktop {
  .report-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
  }

  .report-title {
    margin: 70px 0;
    font-size: 45px;
    font-weight: bold;
  }
  .report-reports {
    width: 80%;

    .report-reports-wrapper {
      width: 100%;
      height: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;
      border: 1px solid rgb(105, 105, 105);
      ul {
        width: 100%;
        padding: 15px;

        .report {
          width: 100%;
          height: 100px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          margin-bottom: 5px;

          .report-name {
            display: flex;
            align-items: center;
            font-size: 25px;
          }

          .report-download {
            width: 50px;
            height: 50px;
            color: black;
          }
        }
      }
    }
  }
}

@include mobile {
  .report-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .report-title {
    margin: 30px 0;
    font-size: 25px;
    font-weight: bold;
  }
  .report-reports {
    width: 80%;

    .report-reports-wrapper {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;
      border: 1px solid black;
      ul {
        width: 100%;
        padding: 15px;

        .report {
          width: 100%;
          height: 50px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          margin-bottom: 3px;

          .report-name {
            display: flex;
            align-items: center;
            font-size: 15px;
          }

          .report-download {
            width: 25px;
            height: 25px;
            color: black;
          }
        }
      }
    }
  }
}

.wrap {
  min-height: 100vh;
  background-color: $wrap-color;
}

@include desktop{
  .container {
    padding-top: $header-size;
    min-height: calc(100vh - #{$header-size} - #{$footer-size});
    background-color: $container-color;
  }
}

@include mobile{
  .container {
    padding-top: $mobile-header-size;
    min-height: calc(90vh - #{$mobile-header-size});
    background-color: $container-color;
  }
}
// screen
$breakpoint-desktop: 1024px;

// header
$header-size: 100px;
$mobile-header-size: 50px;

// footer size
$footer-size: 180px;
$mobile-footer-size: 140px;

$wrap-color: white;
$container-color: white;
$header-color: white;
$footer-color: rgb(227, 224, 224);




@include desktop{
  .navigate-button{
    width: 125px;
    text-align: center;
    font-size:18px;
    &.active{
      border-bottom: 2px solid black;
    }
  }

  .introduce-button{
    width: 100px;
    height: 40px;
    background-color: white;
    border: 1px solid black;
    color:black;
    font-size:15px;
    border-radius: 30px;
    text-align: center;
    margin: 5px 5px 0 5px;
    
    cursor: pointer;
    &.active{
      background-color: black;
      color:white;
    }
  }
}

@include mobile{
  .navigate-button{
    width: 100px;
    font-size:14px;
    text-align: center;
    &.active{
      border-bottom: 2px solid black;
    }
  }

  .introduce-button{
    width: 60px;
    height: 20px;
    background-color: white;
    border: 1px solid black;
    color:black;
    font-size:10px;
    border-radius: 30px;
    text-align: center;
    margin: 5px 5px 0 5px;
    cursor: pointer;
    &.active{
      background-color: black;
      color:white;
    }
  }
}


@include desktop{
  .greeting-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    position : relative;
    margin: 70px 0;
    .greeting-decoration{
      position : absolute;
      right: 60px;
      bottom:0;
      opacity: 40%;
      width: 400px;
      height: 400px;
    }

    .greeting-title{
      margin-bottom : 70px;
      font-size: 45px;
      font-weight: bold;
    }
    .greeting-details{
      width: 75%;
      .greeting-details-title{
        font-size: 40px;
        font-weight: 30px;
        margin-bottom: 40px;
        line-height:120%;
      }
      .greeting-details-writes{
        font-size: 23px;
        margin-bottom: 50px;
        font-weight: 5px;
        line-height:135%;
      }

      .greeting-details-boss{
        font-size: 25px;
        margin-bottom: 50px;
        font-weight: 600;
      }
    }
  }
}

@include mobile{
  .greeting-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    position : relative;
    margin: 50px 0;
    .greeting-decoration{
      position : absolute;
      right: 60px;
      bottom: 20px;
      opacity: 40%;
      width: 100px;
      height: 100px;
    }

    .greeting-title{
      margin-bottom : 30px;
        font-size: 25px;
        font-weight: bold;
    }
    .greeting-details{
      width: 75%;
      .greeting-details-title{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        line-height:120%;
      }
      .greeting-details-writes{
        font-size: 12px;
        margin-bottom: 20px;
        font-weight: 5px;
        line-height:135%;
      }

      .greeting-details-boss{
        font-size: 12.5px;
        margin-bottom: 25px;
        font-weight: 600;
      }
    }
  }
}
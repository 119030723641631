// Desktop
@include desktop{
  .slide-wrap{
    width : 100%;
    height : 43.75vw;
    position: relative;
    display:flex;
    align-items: flex-end;
    justify-content: center;
    

    .main-image{
      position: absolute;
      width: 100%;
      height: 100%;
      
      opacity: 0;
      transition: opacity 0.8s ease-in-out;
    }
    .main-image.active {
      opacity: 1;
    }

    .slide-button-list{
      display: flex;
      justify-content: space-evenly;
      z-index: 1;
      width: 180px;
      height: 30px;
    
    
      .slide-button{
        display: block;

        display: flex;

        .left{
          width: 5px;
          height: 10px;
          border-radius: 5px 0 0 5px;
          background-color: white;
          transition-duration: 1s;
          &.active{
            width: 20px;
            background-color: black;
          }
        }

        .center{
          width: 0px;
          height: 10px;
          transition-duration: 1s;
          background-color: white;
          &.active{
            width: 20px;
            background-color: black;
          }
        }

        .right{
          width: 5px;
          height: 10px;
          border-radius: 0 5px 5px 0;
          background-color: white;
          transition-duration: 1s;
          &.active{
            width: 20px;
            background-color: black;
          }
        }
        
      }

      .slide-button.active{
        background-color: black;
        width: 30px;
      }
    }
  }
}

// Mobile
@include mobile{
  .slide-wrap{
    width : 100%;
    height : 43.75vw;
    position: relative;
    display:flex;
    align-items: flex-end;
    justify-content: center;
    
  
    .main-image{
      position: absolute;
      width: 100%;
      height: 100%;
      
      opacity: 0;
      transition: opacity 0.8s ease-in-out;
    }
    .main-image.active {
      opacity: 1;
    }
  
    .slide-button-list{
      display: flex;
      justify-content: space-evenly;
      z-index: 1;
      width: 164px;
      height: 24px;
    
    
      .slide-button{
        display: block;
  
        display: flex;
  
        .left{
          width: 4px;
          height: 8px;
          border-radius: 4px 0 0 4px;
          background-color: white;
          transition-duration: 1s;
          &.active{
            width: 20px;
            background-color: black;
          }
        }
  
        .center{
          width: 0px;
          height: 8px;
          transition-duration: 1s;
          background-color: white;
          &.active{
            width: 16px;
            background-color: black;
          }
        }
  
        .right{
          width: 4px;
          height: 8px;
          border-radius: 0 4px 4px 0;
          background-color: white;
          transition-duration: 1s;
          &.active{
            width: 16px;
            background-color: black;
          }
        }
        
      }
  
      .slide-button.active{
        background-color: black;
        width: 24px;
      }
    }
  }
}
@include desktop{
  .navigate-buttons-section{
    width: 100%;
    height: 100px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;

  }
}

@include mobile{
  .navigate-buttons-section{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;

  }
}
//Desktop
@include desktop{
  .writeInfo-wrap{
    width: 100%;
    height: 100%;
    display:flex;
    box-sizing: border-box;
    padding: 40px 80px;
    overflow:hidden;
    justify-content: space-evenly;
    align-items: center;

    z-index : 3;
    
    .writeInfo-first,
    .writeInfo-second,
    .writeInfo-third{
      &.wrap{
        width: 100%;
        opacity: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top : 100px;
        padding: 0 20px;
        margin : 10px 0;
      }
      
      transition-duration:1.5s;
      &.hover{
        opacity: 100%;
        top:0px;
      }
    }
  
    .writeInfo-second {
      transition-delay: 0.5s; // 첫 번째 요소 이후에 시작
    }
    
    .writeInfo-third {
      transition-delay: 1s; // 두 번째 요소 이후에 시작
    }
  
    .title{
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 30px;
  
      &.writeInfo-first {
        color:rgb(13, 147, 53);
      }
  
      &.writeInfo-second {
        color:orange;
      }
      &.writeInfo-third {
        color:skyblue;
      }
    }
    
    .subTitle{
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 30px;

    }
  
    .detail{
      font-size: 20px;
      white-space: pre-wrap;
      line-height:125%;
    }
  }
}

// Mobile
@include mobile{
  .writeInfo-wrap{
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    flex-direction: columns;
    align-items: center;
    justify-content: center;
    z-index : 3;

    height: 100%;
    
    .writeInfo-first,
    .writeInfo-second,
    .writeInfo-third{
      &.inner-wrap{
        opacity: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        left : 100px;
        padding: 0 20px;
        margin: 30px 0;
      }
      
      transition-duration:1.5s;
      &.hover{
        opacity: 100%;
        left:0px;
      }
    }
  
    .writeInfo-second {
      transition-delay: 0.5s; // 첫 번째 요소 이후에 시작
    }
    
    .writeInfo-third {
      transition-delay: 1s; // 두 번째 요소 이후에 시작
    }
  
    .title{
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 5px;
  
      &.writeInfo-first {
        color:rgb(13, 147, 53);
      }
  
      &.writeInfo-second {
        color:orange;
      }
      &.writeInfo-third {
        color:skyblue;
      }
    }
    
    .subTitle{
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    .detail{
      font-size: 12px;
      white-space: pre-wrap;
      line-height:120%;
    }
  }
}


@include desktop{
    .history-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin : 70px 0;
        
      }
      
      .history-title{
        margin-bottom : 70px;
        font-size: 45px;
        font-weight: bold;
        
      }
      
      .history-writes{
        font-size: 20px;
        margin-bottom : 40px;
        width: 80%;
        text-align: center;

      }
      .card-body{
        width: 800px;
      }
}

@include mobile{
    .history-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin : 50px 0;
      }
      
      .history-title{
        margin-bottom : 30px;
        font-size: 25px;
        font-weight: bold;
      }
      
      .history-writes{
        font-size: 15px;
        margin-bottom : 20px;
        line-height: 130%;
        width: 80%;
        text-align: center;
      }

      .card-body{
        width: 400px;
      }
}



    .timeline {
        border-left: 3px solid black;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        margin: 0 auto;
        letter-spacing: 0.2px;
        position: relative;
        line-height: 1.4em;
        font-size: 1.03em;
        padding: 50px;
        list-style: none;
        text-align: left;
        width: 70%;
    }


.timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}

.timeline .event {
    border-bottom: 1px dashed #e8ebf1;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -207px;
    content: attr(data-date);
    text-align: right;
    font-weight: 400;
    font-size: 30px;
    min-width: 120px;
}
    
.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px black;
    box-shadow: 0 0 0 3px black;
    left: -55.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 5px;
}

.rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid black;
}

.rtl .timeline .event::before {
    left: 0;
    right: -170px;
}

.rtl .timeline .event::after {
    left: 0;
    right: -55.8px;
}


@include mobile () {
  .timeline {
      max-width: 98%;
      padding: 25px;
      font-size:12px;
      
  }
  .timeline .event {
    padding-top: 30px;
}
    .timeline .event:before {
        left: 0px;
        text-align: left;
    }
    .timeline .event:after {
        left: -31.8px;
    }

    .timeline .event:before {

        font-weight: bold;
        font-size: 20px;
        min-width: 120px;
        
    }

    .timeline h2,
    .timeline h3 {
    
    font-size: 15px;
    margin-bottom: 5px;
}

}


// Desktop
@include desktop{
  .siteActivityComponent{
    background-color: black;
    width: 400px;
    height: 200px;
    border-radius: 20px;
    box-sizing: border-box;
    padding : 30px;
    margin : 30px;
  
    .siteActivityComponent-inner{
      display: flex;
      
      .siteActivityComponent-info{
        flex:1;
  
        .main-info{
          font-size:60px;
          font-weight: 300;
          color: white;
          margin-bottom: 5px;

          .main-info-count-unit{
            font-size: 30px;
          }
        }
        .main-info-unit{
          color: white;
          font-size:30px;
          font-weight: 10;
          margin-bottom: 10px;
        }
  
        .main-info-unit-writes{
          color: white;
          font-size:15px;
          font-weight: 10;
        }
      }
      
      .siteActivityComponent-img{
        flex:1;
      }
    }
  }
}

// Mobile
@include mobile{
  .siteActivityComponent{
    height: 80px;
    box-sizing: border-box;
    margin : 5px 0;
    .siteActivityComponent-inner{
      display: flex;
      
      .siteActivityComponent-info{
        flex:1;
  
        .main-info{
          font-size:25px;
          font-weight: 300;
          margin-bottom: 0.5px;
        }
        .main-info-unit{
          font-size:15px;
          font-weight: 10;
          margin-bottom: 1px;
        }
  
        .main-info-unit-writes{
          font-size:10px;
          font-weight: 10;
          
        }
      }
      
      .siteActivityComponent-img{
        flex:1;
      }
    }
  }

}


*,
*::before,
*::after {
  box-sizing: border-box;
  
}
button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

body, tspan {
  font-family: 'Noto Sans KR', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


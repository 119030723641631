// Desktop
@include desktop{
  .halfPictureHalfWrites-wrap{
    display: flex;
    height: 100%;
    width: 100%;
    
    .half-picture-container{
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // overflow : hidden;
      .half-picture{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    
    .half-writes{

      background-color: black;
      box-sizing: border-box;
      padding:50px;
      width: 50%;
  
      .half-writes-title{
        width: 80%;
        font-size: 30px;
        font-weight: bold;
        color: white;
        margin:50px 0 50px 0;
      }
  
      .half-writes-detail{
        width: 83%;
        color: white;
        margin-bottom: 50px;
        line-height: 200%;
        font-size : 17px;
      }
  
      .half-writes-button{
        width: 100px;
        height: 25px;
        padding:10px;
        border-radius: 10px;
        border : 0.75px solid white;
        color: white;
        text-align: center;
      }
  
      .half-writes-button:hover{
        color: black;
        background-color:  #E3BA19
      }
    }
  }
}

// Mobile
@include mobile{
  .halfPictureHalfWrites-wrap{
    height: 100%;
    width: 100%;
  
    .image-container{
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .half-picture{
        // width: auto; height: auto;
        // max-width: 1024px;
        // object-fit: contain;
        // object-fit: none;
        // height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    .half-writes{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: black;
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      height: 40%;
  
      .half-writes-title{
        width: 80%;
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
  
      .half-writes-detail{
        width: 80%;
        font-size:12px;
        color: white;
        margin-top: 15px;
        line-height: 150%;
      }
  
      .half-writes-button{
        width: 70px;
        height: 20px;
        padding:10px;
        border-radius: 10px;
        border : 0.75px solid white;
        font-size:12px;
        color: white;
        margin-top: 15px;
        text-align: center;
      }
  
      .half-writes-button:hover{
        color: black;
        background-color:  #E3BA19
      }
    }
  }
}
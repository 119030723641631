@include desktop{
  .directions-wrapper{
    display : flex;
    flex-direction: column;
    align-items: center;
    margin : 70px 0;
    

    .directions-title{
      margin-bottom : 70px;
      font-size: 45px;
      font-weight: bold;
    }

    .directions-info{
      height: 400px;
      width: 80%;
      display: flex;
      .directions-info-left{
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;
        .directions-info-name{
          font-size:30px;
          font-weight: bold;
          margin-bottom:20px;
        }
        .directions-info-address{
          font-size: 30px;
          margin-bottom:40px;
        }
    
        .directions-info-route{
          .directions-info-route-row1{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .directions-logo{
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            p{
              font-size:25px;
              font-weight: 300;
            }
          }
          .directions-info-route-row2{
            p{
              font-size:25px;
              font-weight: 300;
            }
            margin-bottom: 40px;
          }
        }
      }
      .directions-info-right{
        flex: 6;
        display: flex;
        flex-direction: column;
        .directions-info-map
        {
          width: 100%;
          height: 100%;
        }
        
      }
    }
  }
}


@include mobile{
  .directions-wrapper{
    display : flex;
    flex-direction: column;
    align-items: center;
    margin : 30px 0;
    

    .directions-title{
      margin-bottom : 30px;
        font-size: 25px;
        font-weight: bold;
        
    }

    .directions-info{
      width: 80%;
      .directions-info-map{
        height: 300px;
        margin-bottom: 20px;
      }

      .directions-info-name{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .directions-info-address{
        font-size: 18px;
        margin-bottom: 20px;
      }

      .directions-info-route-row1{
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom : 5px;
        .directions-logo{
          margin-right: 10px;
        }
      }

      .directions-info-route-row2{
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    

  }
}